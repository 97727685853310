<template>
  <div class="input-full">
    <label
      v-if="label !== ''"
      :for="name"
    > {{ label }} </label>
    <div
      :id="uniqueID"
      class="input-wrapper"
      :class="customClass"
    >
      <div
        v-show="prefix"
        class="prefix"
      >
        {{ prefix }}
      </div>
      <input
        :type="isPassword ? passwordType : typeInput"
        :value="value"
        :name="name"
        :placeholder="placeholder"
        :list="listName"
        :class="[decideClass(), injectOtherClass]"
        :tabindex="tabindex"
        autocomplete="off"
        @input="(e) => onInput(e)"
        @change="onChange()"
        @click="onClickInput($event)"
        @keypress="$emit('keypress', $event)"
        @focus="$emit('focus', $event)"
        @keydown.enter="$emit('enter', $event.target.value)"
        @paste="$emit('paste', $event)"
      >
      <slot name="icon" />
      <div
        v-if="isPassword"
        class="show-hide-password"
        @click="togglePassword"
      >
        <img
          v-if="passwordType === 'password'"
          src="/images/invisible.svg"
          alt="eye icon"
          height="15"
          width="16"
        >
        <img
          v-else
          src="/images/eye.svg"
          alt="eye icon"
          height="15"
          width="16"
        >
      </div>
    </div>
    <div
      v-show="isError && errorMessage !== ''"
      class="field-error-message"
    >
      <img
        :src="`/images/ics_f_warning_triangles.svg`"
        alt="Icon Warning"
      >
      <span class="error-message">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
import { toNumberOnly } from './utils';

export default {
  name: 'Input',
  props: {
    injectOtherClass: {
      type: String,
      required: false,
    },
    customClass: {
      type: String,
      required: false,
      default: '',
    },
    listName: {
      type: String,
      required: false,
      default: '',
    },
    minimalInputNumber: {
      type: Number,
      default: 0,
    },
    maximalInputNumber: {
      type: Number,
      default: 0,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    showDelButton: {
      type: Boolean,
      default: false,
    },
    value: {
      default: '',
      type: String,
    },
    placeholder: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    typeInput: {
      type: String,
      default: 'text',
    },
    className: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    prefix: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: 'this field is required',
    },
    tabindex: {
      type: String,
      default: '-1',
    },
    customPlaceholder: {
      type: String,
      default: '',
    },
    isPassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uniqueID: `${this.name.replace(/ /g, '-').toLowerCase()}-${(+new Date()).toString(36).slice(-5)}`,
      animation: {
        out: 'fade-out',
        in: 'fade-in',
      },
      classInputNumberSpin: 'style-input-number',
      passwordType: 'password',
    };
  },
  mounted() {
    const inputNumber = document.querySelector(`#${this.uniqueID} input[type=number]`);
    if (inputNumber) {
      if (this.minimalInputNumber > 0) {
        inputNumber.setAttribute('min', String(this.minimalInputNumber));
      }
      if (this.maximalInputNumber > 0) {
        inputNumber.setAttribute('max', String(this.maximalInputNumber));
      }
      inputNumber.classList.add(this.classInputNumberSpin);
    }
  },
  methods: {
    togglePassword() {
      if (this.passwordType === 'password') {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    },
    decideClass() {
      let className = 'input-breakpoint-style ';
      if (!this.className) {
        className += 'input-full ';
      } else {
        className += this.className;
        className += ` style-${this.className} `;
      }

      if (this.prefix) {
        className += ' padding-input-prefix';
      }

      if (this.disable) {
        className += ' input-disabled ';
      }

      if (this.customPlaceholder) {
        className += ` ${this.customPlaceholder}`;
      }

      return className;
    },
    deleteSpinInputNumber(isDelete) {
      const inputNumber = document.querySelector(`#${this.uniqueID} input[type=number]`);
      if (inputNumber) {
        if (isDelete) {
          inputNumber.classList.remove(this.classInputNumberSpin);
        } else {
          inputNumber.classList.add(this.classInputNumberSpin);
        }
      }
    },
    deleteIfInputNumberHasValue() {
      if (this.value.length > 0) {
        this.deleteSpinInputNumber(true);
      } else {
        this.deleteSpinInputNumber(false);
      }
    },
    onInput(event) {
      const { value } = event.target;

      if (this.prefix === '+62') {
        let phoneNumber = toNumberOnly(value);
        if (value.substring(0, 1) === '+') {
          phoneNumber = value.replace('+', '');
        } else if (value.substring(0, 1) === '0') {
          phoneNumber = value.replace('0', '');
        } else if (value.substring(0, 2) === '62') {
          phoneNumber = value.replace('62', '');
        }

        event.target.value = phoneNumber;
        this.$emit('input', phoneNumber);
      } else {
        this.$emit('input', value);
      }
    },
    onChange() {
      this.deleteIfInputNumberHasValue();
    },
    onClickInput(ev) {
      this.deleteIfInputNumberHasValue();
      this.$emit('click', ev.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
